<template>
  <div class="priview-pdf-img">
    <!--  自己设置图片路径-->
    <!-- <pdf :src="pdfUrls" ref="pdf"></pdf> -->
    <img :src="imgUrl" alt="">
  </div>
</template>
<script>
// import pdf from 'vue-pdf-signature'
// import CMapReaderFactory from 'vue-pdf-signature/src/CMapReaderFactory.js'
export default {
  name: 'priview-pdf',
  components: {
    // pdf
  },
  props: {
    // 外部传递pdfUrl 即可
    pdfUrl: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
    },
  },
  data () {
    return {
      imgUrl:''
    }
  },
  mounted() {
    // this.pdfTask(this.pdfUrl)
  },
  methods: {
    pdfTask(url){
      var self = this
      // 传参 CMapReaderFactory
      this.pdfUrls = pdf.createLoadingTask({ url: url, CMapReaderFactory })
      console.log('pdf',this.pdfUrls)
      this.pdfUrls.promise.then(pdf => {
        self.numPages = pdf.numPages
      }).catch((err) => {
        console.error('pdf加载失败')
      })
      // 加载完PDF后对缓存进行清除
      for(var key in require.cache) {
        if(key.indexOf('bcmap') >= 0) {
          delete require.cache[key];
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.priview-pdf-img {
  img {
    width: 100px;
    height: 100px;
  }
}
</style>
