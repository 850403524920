<template>
  <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialogs" :confirmDialog='importConfirm' :isdeling='isdeling'>
    <priview-pdf :pdfUrl="pdfUrl"></priview-pdf>
    <!-- <div class="main-section" id="content" ref="contentDiv" v-loading='loading'></div>
    存储位置：增值税销项电子发票目录/2021年/07月 -->
  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog";
  import PriviewPdf from "@/components/PriviewPdf";
  // import {parseOfdDocument, renderOfd} from "ofd.js";
  export default {
    props: {
      period: {
        type: String,
      },
      visible: {
        type: Boolean,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
    },
    components: {
      Dialog,
      PriviewPdf
    },
    data() {
      return {
        // pdfUrl: 'http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf',
        pdfUrl: '/static/test.pdf',
        // pdfUrl: '/static/test.ofd',

        config: {
          top: '10vh',
          width: '46rem',
          type: 'PDF',
          title: '042002000211_60278288_20201214.pdf',
          center: true,
          btnTxt: [],
        },
        fileList: [],
        fileData: '',
        isdeling: false,
        tableData: [],
        status:  false,
        message : '请选择上传文件',
        screenWidth :'840',
        loading : false,
      };
    },
    methods: {
      cancelDialogs(){
        this.cancelDialog();
      },
      importConfirm(){
        this.cancelDialog();
      },
    },
    // mounted(){
    //   this.getOfdDocumentObj(this.fileUrl, this.screenWidth);
    // },
    watch:{
      visible(val){
        if(val){
          // this.demo();
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .firstStep {
    margin: 24px 0 12px;
  }
  .upload {
    cursor: pointer;
    color: #4f71ff;
  }
  .main-section{
    min-height: 500px;
  }
</style>
